import styled from 'styled-components';

import { textStyles as styles } from 'theme/theme';

import { Text } from './Text';

export const StyledRichText = styled(Text)<{
  $simple: boolean;
  $small: boolean;
  $normalize: boolean;
  $textAlignCenter: boolean;
  $noMargin: boolean;
  $color: string | undefined;
}>`
  overflow-wrap: break-word;

  &.idlst-rchtxt {
    /* block-no-empty */
    ${(props) =>
      props.$simple &&
      `
    h1, h2, h3, h4, h5 {
      margin: 1em 0;
      font-size: inherit !important;
    }
  `}
    ${(props) =>
      props.$small &&
      `
    ${styles.bodySmall}

    h1, h2, h3, h4 {
      margin: 1em 0;
      ${styles.h4}
    }
  `}

  ${(props) =>
      props.$normalize &&
      `
    h1, h2, h3 {
      ${styles.h3}
    }

    h4, h5 {
      font-size: inherit !important;
    }

    h1, h2, h3, h4, h5 {
      margin: 1em 0;
    }
  `}
  }

  ${(props) => props.$textAlignCenter && 'text-align: center;'};
  ${(props) => props.$noMargin && 'margin: 0;'};
  ${(props) => props.$color && `color: ${props.$color};`};
`;
